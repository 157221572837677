import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap";

import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import jsonData from "../components/Contents/ServiceContent.json";

import heroImage from '../images/Marriage/MarriageBanner.png'
import EngagementImage from '../images/Marriage/Engagement.png'
import HennaImage from '../images/Marriage/Henna.png'
import MarriageImage from '../images/Marriage/Marriage.png'

const lang = 'de'

function Marriage() {
  const [ userLanguage, setUserLanguage ] = useState (lang);

  useEffect(() => {
    localStorage.getItem('lang') ? setUserLanguage(localStorage.getItem('lang')) : setUserLanguage(lang);
  }, [])
  

  return (
    <Container
      fluid
      style={{
        backgroundColor: "#1a1718",
        marginBottom: "0",
        maxWidth: "100%",
        paddingRight: "0",
        paddingLeft: "0",
      }}
    >
      <NavBar  setLanguage={setUserLanguage} userLanguage={userLanguage} />

      <div
        id="hero"
        style={{
          backgroundImage: `url(${heroImage})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="hero-content-other py-2" data-aos="fade-up">
          <h2 className="font-effect-anaglyph my-2">
            {userLanguage==='en' ? jsonData.engMarriage.title : (userLanguage==='de' ? jsonData.deMarriage.title : jsonData.trMarriage.title)}
          </h2>
        </div>
      </div>

      <div
        className="product-desciption"
        style={{ backgroundColor: "#eeeeee" }}
      >
        <Container
          style={{
            maxWidth: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row className="">
            <Col md={6} className="product-image-left">
              <img
                src={EngagementImage}
                width="80%"
                height="80%"
                className="d-inline-block"
                alt="marriage ceremony"
              />
            </Col>
            <Col md={6}>
            <div className="px-5 product-description" >
              <h2>
                {userLanguage==='en' ? jsonData.engMarriage.head1 : (userLanguage==='de' ? jsonData.deMarriage.head1 : jsonData.trMarriage.head1)}
              </h2>
              <p>
                {userLanguage==='en' ? jsonData.engMarriage.paragraph1 : (userLanguage==='de' ? jsonData.deMarriage.paragraph1 : jsonData.trMarriage.paragraph1)}
              </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="product-desciption"
        style={{ backgroundColor: "#f3eeb5" }}
      >
        <Container
          style={{
            maxWidth: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row className="">
            <Col md={6}>
            <div className="px-5 product-description" >
            <h2>
                {userLanguage==='en' ? jsonData.engMarriage.head2 : (userLanguage==='de' ? jsonData.deMarriage.head2 : jsonData.trMarriage.head2)}
              </h2>
              <p>
                {userLanguage==='en' ? jsonData.engMarriage.paragraph2 : (userLanguage==='de' ? jsonData.deMarriage.paragraph2 : jsonData.trMarriage.paragraph2)}
              </p>
              </div>
            </Col>
            <Col md={6} className="product-image-right">
              <img
                src={HennaImage}
                width="80%"
                height="80%"
                className="d-inline-block"
                alt="marriage ceremony"
              />
            </Col>
          </Row>
        </Container>
      </div>


      <div
        className="product-desciption"
        style={{ backgroundColor: "#eeeeee" }}
      >
        <Container
          style={{
            maxWidth: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Row className="">
            <Col md={6} className="product-image-left">
              <img
                src={MarriageImage}
                width="80%"
                height="80%"
                className="d-inline-block"
                alt="marriage ceremony"
              />
            </Col>
            <Col md={6}>
            <div className="px-5 product-description" >
            <h2>
                {userLanguage==='en' ? jsonData.engMarriage.head3 : (userLanguage==='de' ? jsonData.deMarriage.head3 : jsonData.trMarriage.head3)}
              </h2>
              <p>
                {userLanguage==='en' ? jsonData.engMarriage.paragraph3 : (userLanguage==='de' ? jsonData.deMarriage.paragraph3 : jsonData.trMarriage.paragraph3)}
              </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </Container>
  );
}

export default Marriage